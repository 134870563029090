html {
  background-color: black;
  padding: 0;
  margin: 0;
  font-family: "Bitter", serif;
  overflow-x: hidden;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
